import { navigate } from 'gatsby'
import React from 'react'
import Container from '../Container'
import AlertConfig from '../../ALERTBAR_CONFIG'

import BackImg from '../../images/icons/arrow.png'

function navbar(props) {
	return (
		<nav
			className="bg-white z-10 py-4 flex items-center justify-start fixed top-0 left-0 right-0"
			style={{
				borderBottom: 'solid 1px #F5F5F5',
				height: '60px',
				marginTop: AlertConfig.enabled ? '98px' : '58px',
			}}
		>
			<Container no-vertical>
				<span>
					<button
						className="hover:underline text-black focus:outline-none flex items-center"
						onClick={props.onClick || (() => navigate(-1))}
					>
						<img
							src={BackImg}
							alt=""
							width="96"
							height="96"
							className="h-5 w-auto rotate-[270deg] mr-2"
						/>{' '}
						Return to {props.children}
					</button>
				</span>
			</Container>
		</nav>
	)
}

export default navbar
