import React from 'react'
import Layout from '../../components/layouts/blog/post'
import { graphql } from 'gatsby'
import Container from '../../components/Container'

import TwitterLogo from '../../images/icons/classify twitter alt.webp'
import FacebookLogo from '../../images/icons/classify facebook alt.webp'
import LinkedinLogo from '../../images/icons/classify linkedin.webp'
import { Helmet } from 'react-helmet'

export default function BlogPost({ data }) {
	const post = data.allWpPost.nodes[0]
	const author = post.author.node
	const featuredImage = post.featuredImage?.node
	const topImage = post.blogContent.topImage

	const strippedExcerpt = post.excerpt
		.replace(/(<([^>]+)>)/gi, '')
		.split(' ')
		.splice(0, 54)
		.join(' ')

	return (
		<>
			<Helmet>
				<title>{post.title}</title>
				<meta name="description" content={post.excerpt} />
				<link
					rel="canonical"
					href={'https://classify.org.uk/blog/' + post.slug}
				/>

				<meta property="og:title" content={post.title} />
				<meta
					property="og:url"
					content={'https://classify.org.uk/blog/' + post.slug}
				/>
				<meta
					property="og:image"
					content={
						topImage
							? topImage.sourceUrl
							: 'https://i.imgur.com/ODTJzzm.png'
					}
				/>
				<meta property="og:description" content={strippedExcerpt} />
				<meta property="og:type" content="website" />

				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:site" content="@classifyapp" />
				<meta property="twitter:title" content={post.title} />
				<meta
					name="twitter:image"
					content={
						topImage
							? topImage.sourceUrl
							: 'https://i.imgur.com/ODTJzzm.png'
					}
				/>
				<meta
					property="twitter:description"
					content={strippedExcerpt}
				/>
			</Helmet>
			<Layout>
				<Container no-vertical>
					<div className="text-center grid gap-16 justify-items-center pt-32 pb-16 mx-auto">
						<div className="lg:w-6/12">
							<span className="text-4xl font-black">
								{post.title}
							</span>
						</div>
						<p
							className="text-xl lg:w-8/12"
							dangerouslySetInnerHTML={{
								__html: strippedExcerpt,
							}}
						/>
						<span className="flex justify-center items-center font-regular">
							<img
								className="rounded-full mr-2"
								src={author.avatar.url}
								alt="Author avatar"
								height="24"
								width="24"
							/>
							{author.name}
						</span>
					</div>
				</Container>
				{featuredImage && (
					<Container no-vertical>
						<div className="pb-12">
							<img
								src={featuredImage.sourceUrl}
								alt={featuredImage.altText}
								className="rounded-2xl"
							/>
							<div
								className="text-center mt-1 text-black-alt"
								dangerouslySetInnerHTML={{
									__html: featuredImage.caption,
								}}
							/>
						</div>
					</Container>
				)}
				<Container no-vertical>
					<div
						className="prose mx-auto mt-0"
						dangerouslySetInnerHTML={{ __html: post.content }}
					/>
					<div className="flex flex-col items-center justify-center pt-12 pb-20">
						<span className="text-black-faded pb-12">Share</span>
						<div className="grid grid-cols-3 gap-12 opacity-40">
							<a
								href={`https://twitter.com/intent/tweet?url=https://classify.org.uk/blog/${post.slug}/`}
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									src={TwitterLogo}
									alt="Share to Twitter"
									width="32"
									height="32"
								/>
							</a>
							<a
								href={`https://www.linkedin.com/shareArticle?mini=true&url=https://classify.org.uk/blog/${post.slug}/`}
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									src={LinkedinLogo}
									alt=" Share to Linkedin"
									width="32"
									height="32"
								/>
							</a>
							<a
								href={`https://www.facebook.com/sharer.php?u=https://classify.org.uk/blog/${post.slug}/`}
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									src={FacebookLogo}
									alt="Share to Facebook"
									width="32"
									height="32"
								/>
							</a>
						</div>
					</div>
					<hr className="lg:w-4/5 mx-auto border-black-faded" />
				</Container>
			</Layout>
		</>
	)
}

export const query = graphql`
	query ($slug: String!) {
		allWpPost(filter: { slug: { eq: $slug } }) {
			nodes {
				title
				excerpt
				slug
				author {
					node {
						name
						avatar {
							url
						}
					}
				}

				blogContent {
					topImage {
						sourceUrl
						altText
					}
				}

				featuredImage {
					node {
						sourceUrl
						caption
						altText
					}
				}
				content
			}
		}
	}
`
