import React from 'react'
import Layout from '../index'
import Backbar from '../../navigation/backbar'
import Container from '../../Container'
import { StaticQuery, graphql, navigate } from 'gatsby'
import PostCard from '../../PostCard'
import Subscribe from '../../Subscribe'

function index(props) {
	return (
		<Layout>
			<Backbar onClick={() => navigate('/blog')}>Blog</Backbar>
			<div>{props.children}</div>
			<section id="weekly-tab">
				<Container no-vertical>
					<div className="py-16 text-center flex flex-col items-center">
						<h1 className="text-black pb-8 font-college font-medium">
							THE WEEKLY TAB
						</h1>
						<p className="text-black-alt text-xl md:w-96 pb-8">
							Stories, guides and ideas written to broaden
							perspectives and provide helpful insights. 🔥
						</p>
						<Subscribe />
					</div>
					<div className="grid lg:grid-cols-2 gap-8 mb-16">
						<StaticQuery
							query={graphql`
								query {
									allWpPost(
										filter: {
											categories: {
												nodes: {
													elemMatch: {
														slug: { ne: "updates" }
													}
												}
											}
										}
									) {
										nodes {
											slug
											title
											author {
												node {
													name
													avatar {
														url
													}
												}
											}

											blogContent {
												topImage {
													sourceUrl
													altText
												}
											}

											categories {
												nodes {
													name
												}
											}
										}
									}
								}
							`}
							render={(data) => {
								return data.allWpPost.nodes.map(
									(post, index) => (
										<PostCard
											title={post.title}
											slug={post.slug}
											category={
												post.categories.nodes[0].name
											}
											imageUrl={
												post.blogContent.topImage
													?.sourceUrl
											}
											author={post.author.node}
											key={index}
										/>
									)
								)
							}}
						/>
					</div>
				</Container>
			</section>
		</Layout>
	)
}

export default index
